import { Link } from "react-router-dom";
import logo from "../assets/Footer/Rave_0.png"


import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const Footer = () => {
    return ( 
        <footer>
            <div className="flex flex-col bg-no-repeat h-full w-screen bg-cover">
                <img className="mt-8 mx-auto xl:mx-16 pb-8" src={logo}  height="100" width="175" alt="Rave Energy Logo"/>
                <hr className="w-11/12 mx-auto" />
                <div className="flex flex-col xl:flex-col md:flex-row xl:pb-0 xl:mx-0 md:flex-row md:mx-auto md:pb-8">
                    <div className="flex flex-col justify-center xl:justify-start xl:flex-row px-4 sm:px-16 sm:mt-4">
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Arizona</h2>
                            <p className="font-light text-xl">410 N Scottsdale Rd,</p>
                            <p className="font-light text-xl">Tempe, AZ 85281</p>
                        </div>
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Maryland</h2>
                            <p className="font-light text-xl">7761 Diamondback Dr,</p>
                            <p className="font-light text-xl">College Park, MD 20742</p>
                        </div>
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Texas</h2>
                            <p className="font-light text-xl">300 N Coit Rd,</p>
                            <p className="font-light text-xl">Richardson, TX 75080</p>
                        </div>
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Washington D.C</h2>
                            <p className="font-light text-xl">655 New York Ave, NW</p>
                            <p className="font-light text-xl">Washington, DC 20001</p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center md:justify-start xl:justify-start xl:flex-row px-16 xl:mt-16 mb-8">
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Contact</h2>
                            <p className="font-light text-xl">1-855-672-8348</p>
                            <a href="mailto:info@theravecompany.net" target="_blank" rel="noopener noreferrer" className="font-light text-xl hover:text-red-500 transition duration-500">info@theravecompany.net</a>
                        </div>
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Work With Us</h2>
                            <a href="https://raveenergy.bamboohr.com/jobs/" className="font-light text-xl hover:text-red-500 transition duration-500">Careers</a>
                        </div>
                        <div className="sm:px-12 lg:px-3 xl:w-1/4 text-center md:text-left md:px-0 xl:text-left mt-4 xl:mt-0">
                            <h2 className="font-bold text-3xl">Legal</h2>
                            <Link to="/privacy-policy" className="font-light text-xl hover:text-red-500 transition duration-500">Privacy Policy</Link>
                        </div> 
                    </div>
                </div>
                <hr className="w-11/12 mx-auto" />
                <div className="flex flex-col lg:flex-row justify-between items-center p-4 sm:px-16">
                    <p className="font-light text-center order-2 lg:order-1 mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">Copyright ©2023 TZMedia LLC, All Rights Reserved. Proudly made in Texas.</p>
                    <div className="mt-2 sm:mt-0 order-1 lg:order-2">
                        <a style={{ color : `#F72700` }} className="mx-4 " href="https://www.facebook.com/raveenergy2020">
                            <FacebookIcon style={{ fontSize: `40px` }} />
                        </a>
                        <a style={{ color : `#F72700` }} className="mx-4" href="https://instagram.com/raveenergytx">
                            <InstagramIcon style={{ fontSize: `40px` }} />
                        </a>
                        <a style={{ color : `#F72700` }} className="mx-4" href="https://linkedin.com/company/raveenergy">
                            <LinkedInIcon style={{ fontSize: `40px` }} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
     );
}
 
export default Footer;
