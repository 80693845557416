import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';

import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import Companies from "./pages/Companies";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Nav />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/rave-companies">
          <Companies />
        </Route>
        <Route exact path="/about-rave">
          <About />
        </Route>
        <Route exact path="/rave-careers">
          <Careers />
        </Route>
        <Route exact path="/contact-us">
          <Contact />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy/>
        </Route>
        <Route path="/*">
            <NotFound />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
