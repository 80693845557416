import { Link } from "react-router-dom";
import ContactBackground from "../assets/contactBackground.svg";

const Contact = () => {
    return ( 
        <main className="flex flex-col items-center pb-16">
            <h1 className="text-center text-7xl font-bold py-8 mt-8">Contact Us</h1>
            <p className="text-center max-w-2xl font-light mx-auto text-3xl mt-8 p-2">Questions, issues, and comments are all welcome. Remember, your satisfaction is our priority.</p>
            <div style={{ backgroundImage :`url(${ContactBackground})` }} className="flex flex-col items-center bg-center bg-cover w-screen h-full py-24 mt-16">
                <h1 className="text-3xl text-center font-bold mt-8">Want to reach a specific company?</h1>
                <p className=" max-w-2xl text-center font-light mx-auto text-lg mt-6 p-2">The best way to get assistance is to reach the companies directly.  Find them from our complete list of Rave companies and visit their website for relevant contact details.</p>
                <Link to="/rave-companies" className="text-center my-8 w-60 bg-red-500 p-4 rounded-lg text-white font-bold hover:bg-red-700 transition-all duration-300 ">Find a Rave Company</Link>
            </div>
        </main>
     );
}

export default Contact;