import Line from "../assets/line.svg";
import CareersImg from "../assets/careers.svg";


const Careers = () => {
    return ( 
        <main className="px-2 md:px-0 lg:px-0 xl:px-0 flex flex-col items-center pb-16">
            <h1 className="text-7xl text-center font-bold py-8 mt-8">Working at Rave</h1>
            <p className="text-center max-w-2xl font-light mx-auto text-3xl mt-8">Rave is one of the leading companies to work for in our field. We're proud of our high rating from our employees for job satisfaction. We are hiring talented professionals to join our team. </p>
            <p className=" text-center max-w-2xl font-light mx-auto text-3xl mt-8"> If you're excited to be part of a winning team, Rave is a great place to grow your career. You'll be glad you applied to Rave.</p>
            <img alt="Accent" className="mx-auto my-8" src={Line} />
            <h1 className="text-6xl max-w-lg mx-auto text-center font-bold py-8 mt-8">Get the latest on Rave careers</h1>
            <img alt="The Rave Company - Careers" className="mx-auto my-2" src={CareersImg} height="200px" width="500px" />
            <a href="https://raveenergy.bamboohr.com/jobs"className="text-center my-2 w-60 bg-red-500 p-4 rounded-lg text-white font-bold hover:bg-red-700 transition-all duration-300 ">See open positions</a> 
        </main>
     );
}
 
export default Careers;