import Energy from "../assets/companies/energy.jpg";
import Solar from "../assets/companies/helios.png";
import Pay from "../assets/companies/pay.jpg";
import Travel from  "../assets/companies/traveltech.jpg";
import Tzmedia from "../assets/companies/tzmedia.jpg";
import ComBackground from "../assets/companiesBg.svg";


const Companies = () => {
    return ( 
        <main className="mx-auto text-center h-full">
            <div className="items-center bg-cover bg-no-repeat py-16" style={{ backgroundImage : `url(${ComBackground})` }}>
                <h1 className="text-5xl font-bold text-center mb-16">Our Companies</h1>
                <div className="flex flex-col items-center sm:mb-32 md:mb-64 lg:mb-48 sm:mx-auto">
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-rowmy-2">
                        <a href="https://raveenergy.net" className="flex justify-center mx-4  bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Energy} alt="Rave Energy" className="object-contain" height="100%" width="300px" />
                        </a>
                        <a href="https://heliosenergy.us/" className="flex justify-center mt-8 lg:mt-0 xl:mt-0 mx-4  bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Solar} alt="Helios Energy" className="object-contain" height="100%" width="250px" />
                        </a>
                        <a href="https://www.ravepay.us/" className="flex justify-center mt-8 lg:mt-0 xl:mt-0 mx-4  bg-white h-72  w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Pay} alt="Rave Pay" className="object-contain" height="100%" width="170px" />
                        </a>
                    </div>
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row mt-8">
                        <a href="https://raveit.io" className="flex justify-center  mx-4 bg-white h-72 w-72 p-8  object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Travel} alt="Rave Travel Technologies" className="object-contain" height="100%" width="200px" />
                        </a>
                        <a href="https://tzmedia.us" className="flex justify-center mt-8 lg:mt-0 xl:mt-0 mx-4  bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Tzmedia} alt="TZ Media" className="object-contain" height="100%" width="300px" />
                        </a>
                    </div>  
                </div>
            </div>
        </main>
     );
}
export default Companies;

