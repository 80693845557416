import { Link } from "react-router-dom";
import ErrorImg from "../assets/error.svg"

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center px-4 lg:px-0">
            <h1 className="font-bold text-4xl tracking-tighter mt-24 lg:mt-8 text-center">Oops! Something went wrong.</h1>
            <img src={ErrorImg} alt="Page not found." height="500" width="500" />
            <Link className="px-8 py-3 bg-red-500 rounded mt-6 text-white text-center text-xl hover:shadow-md transition duration-500 mb-4" to="/">Home</Link>
        </div>
      );
}
 
export default NotFound;