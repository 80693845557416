import React from 'react';
import { Link } from 'react-router-dom';
import NavLogo from "../assets/navLogo.png";

const Nav = () => {
    const toggleOverlay = () =>  {
        const overlay = document.getElementById('overlay');
        if(overlay.style.opacity === "1") {
            overlay.style.zIndex = "-1";
            overlay.style.opacity = "0";
            document.body.style.overflow = "visible";
            document.getElementById("top").style.transform = "translateY(0px) translateX(0) rotate(0deg)";
            document.getElementById("middle").style.opacity = "100%";
            document.getElementById("bottom").style.transform = "translateY(0px) translateX(0) rotate(0deg)";
        }
        else {
            overlay.style.zIndex = "50";
            overlay.style.opacity = "1";
            document.body.style.overflow = "hidden"
            document.getElementById("top").style.transform = "translateY(7px) translateX(0) rotate(45deg)";
            document.getElementById("middle").style.opacity = "0%";
            document.getElementById("bottom").style.transform = "translateY(-7px) translateX(0) rotate(-45deg)";
        }
    }
    return ( 
        <nav>
            <div className="flex justify-between border-b border-gray-100">
                <div className="bg-gray-100 h-full py-5 pl-2 pr-3">
                    <div onClick={toggleOverlay} className="flex items-center relative cursor-pointer h-6">
                        <div className="relative w-6 ml-6 transform -translate-x-1/2">
                            <div className="w-full h-3px bg-red-500 transition-all" id="top" ></div>
                            <div className="w-full h-3px bg-red-500 transition-all mt-1" id="middle" ></div>
                            <div className="w-full h-3px bg-red-500 transition-all mt-1" id="bottom" ></div>
                        </div>
                        <p className="font-bold text-red-500">MENU</p>
                    </div>
                </div> 
                <img alt="The Rave Company Logo" className="absolute left-1/2 ml-0 lg:-ml-12 xl:-ml-12 -mt-2" src={NavLogo} height="100px" width="100px" />
            </div>
            <div id="overlay" style={{ opacity: `0`, zIndex: `-1`}} className="bg-black bg-opacity-80 bg-cover h-screen w-screen overflow-hidden absolute top-16 left-0 transition-all duration-500">
                <div className="flex flex-col bg-white border-t border-gray-100 sm:w-full ">
                    <div>
                        <ul onClick={toggleOverlay} className="relative sm:ml-20 h-64 lg:h-full xl:h-full overflow-scroll .overflow-y-scroll bg-scroll lg:py-24 xl:py-24 py-6 list-none sm:text-left text-center">
                            <li className="flex-initial">
                                <Link to="/" className="cursor-pointer font-light no-underline hover:text-red-500 text-3xl md:4x1 lg:text-4xl xl:text-4xl">Home</Link>
                            </li> 
                            <li className="mt-6 pb-6 flex-initial">
                                <Link to="/rave-companies" className="cursor-pointer font-light no-underline hover:text-red-500 text-3xl md:4x1 lg:text-4xl xl:text-4xl">Companies</Link>
                            </li>
                            <li className="flex-initial">
                                <Link to="/about-rave" className="cursor-pointer font-light no-underline hover:text-red-500 text-3xl md:4x1 lg:text-4xl xl:text-4xl">About Us</Link>
                            </li> 
                            {/* <li className="mt-8 group h-16">
                                <button class=" cursor-pointer font-light sm:text-3xl no-underline hover:text-red-500 text-5xl flex items-center">
                                    <span class="pr-1 flex-1">Who We Are</span>
                                    <span class="mr-auto">
                                        <svg
                                            class="inline-block fill-current h-8 w-8
                                            transition duration-150 ease-in-out groupa:rotate-180"
                                            viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                        </svg>
                                    </span>
                                </button>
                                <ul class="cursor-pointer font-light sm:text-3xl no-underline text-5xl transform groupa-hover:scale-100 scale-0 relative -top-10 left-48 transition duration-150 ease-in-out w-48">
                                    <li class=" px-3 py-1 hover:text-red-500"><Link to="/about-rave">About Us</Link></li>
                                    <li class="px-3 py-1 hover:text-red-500 mt-4"><Link to="/rave-timeline">Timeline</Link></li>
                                </ul>
                            </li> */}
                            <li className="pt-6 flex-initial">
                                <Link to="/contact-us" className="cursor-pointer font-light no-underline hover:text-red-500 text-3xl md:4x1 lg:text-4xl xl:text-4xl">Contact</Link>
                            </li>
                            <li className="mt-6 flex-initial">
                                <a href="https://blog.theravecompany.net/" className="cursor-pointer font-light no-underline hover:text-red-500 text-3xl md:4x1 lg:text-4xl xl:text-4xl">Blog</a> 
                            </li>
                            {/*<li className="mt-8">
                                <a href="http://club.theravecompany.net/" className="cursor-pointer font-light sm:text-3xl no-underline hover:text-red-500 text-5xl">Rave Club</a> 
                            </li>*/}
                            <li className="mt-6 flex-initial">
                                <Link to="/rave-careers" className="cursor-pointer font-light no-underline hover:text-red-500 text-3xl md:4x1 lg:text-4xl xl:text-4xl">Working At Rave</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
     );
}

 
export default Nav;
