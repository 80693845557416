import AboutBackground from "../assets/aboutBackground.svg";
import tarek from "../assets/headshots/tarek.png"
import alan from "../assets/headshots/allan.png"
import barbara from "../assets/headshots/barbara.png"
import saad from "../assets/headshots/saad.png"
import erteza from "../assets/headshots/erteza.png"
import jeffrey from "../assets/headshots/jeffrey.png"
// import nathan from "../assets/headshots/nathan.png"
import ryan from "../assets/headshots/ryan.png"
import yosef from "../assets/headshots/yosef.png"
import asef from "../assets/headshots/asef.png"

const About = () => {
    return ( 
        <main>
            <h1 className="text-7xl text-center font-bold px-2 py-8 mt-8 text-red-500">What makes us Rave?</h1>
            <p className="max-w-2xl font-light mx-auto text-3xl mt-8 text-center p-4 ">At Rave, we create unique customer interactions to reach our client's goals. Finding an ideal business solution through Rave can assist in optimizing project costs. Our purpose is to help your business grow.</p>
            <div style={{ backgroundImage : `url(${AboutBackground})` }} className="h-full bg-cover bg-center pt-48 pb-96">
                <h1 className="justify-center text-6xl text-center mx-2 font-bold py-8 text-red-500 mt-96 md:mt-96 lg:mt-24">Our Team</h1>
                <div className="flex justify-center items-center mt-0 lg:mt-8 sm:flex flex-col md:flex-col lg:flex-row">
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Tarek: CEO" className="mx-auto mt-4" src={tarek} height="auto" width="215px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-24 text-center text-xs p-1 mt-4 text-red-500 font-medium">TAREK <span className="text-black"> | </span> CEO</p>
                    </div>
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Barbara: Partner" className="mx-auto mt-8" src={barbara} height="auto" width="200px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-32 text-center text-xs p-1 mt-4 text-red-500 font-medium">BARBARA <span className="text-black"> | </span> Partner</p>
                    </div>
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Saadman: Partner" className="mx-auto mt-12" src={saad} height="auto" width="220px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-44 text-center text-xs p-1 mt-4 text-red-500 font-medium">SAADMAN <span className="text-black"> | </span> Vice President</p>
                    </div>
                </div>
                <div className="flex justify-center items-center sm:mt-0 lg:mt-8 sm:flex flex-col md:flex-col lg:flex-row">
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Ryan: CFO" className="mx-auto mt-12" src={ryan} height="auto" width="200px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-20 text-center text-xs p-1 mt-4 text-red-500 font-medium">RYAN <span className="text-black"> | </span> CFO</p>
                    </div>
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Erteza: Director of Operations" className="mx-auto mt-2" src={erteza} height="auto" width="225px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-52 text-center text-xs p-1 mt-4 text-red-500 font-medium">ERTEZA <span className="text-black"> | </span> Director of Operations</p>
                    </div>
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Asef: Partner" className="mx-auto px-4" src={asef} height="auto" width="250px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-24 text-center text-xs p-1 mt-4 text-red-500 font-medium">ASEF <span className="text-black"> | </span> Partner</p>
                    </div>
                    {/* <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Nathanael: Lead Software Engineer" className="mx-auto mt-2" src={nathan} height="auto" width="270px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-56 text-center text-xs p-1 mt-4 text-red-500 font-medium">NATHAN <span className="text-black"> | </span> Lead Software Engineer</p>

                    </div> */}
                </div>
                <div className="flex justify-center items-center sm:mt-0 lg:mt-8 sm:flex flex-col md:flex-col lg:flex-row">
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Allan: Team Lead" className="mx-auto mt-8" src={alan} height="auto" width="250px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-36 text-center text-xs p-1 mt-4 text-red-500 font-medium">ALAN <span className="text-black"> | </span> Team Lead</p>
                    </div>
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Ananno: Team Lead" className="mx-auto mt-8" src={jeffrey} height="auto" width="400px" />
                        </div>
                        <p className="mx-auto rounded bg-white w-36 text-center text-xs p-1 mt-4 text-red-500 font-medium">ANANNO <span className="text-black"> | </span> Team Lead</p>
                    </div>
                    <div className="pt-6 mx-4">
                        <div className="bg-gray-50 rounded-full overflow-hidden h-64 w-64">
                            <img alt="Yosef: Team Lead" className="mx-auto mt-12" src={yosef} height="auto" width="auto" />
                        </div>
                        <p className="mx-auto rounded bg-white w-32 text-center text-xs p-1 mt-4 text-red-500 font-medium">YOSEF <span className="text-black"> | </span> Team Lead</p>
                    </div>
                </div>
                <div className="flex justify-center mt-0 md:mt-8 lg:mt-8 items-center">
                </div>
            </div>
            {/*<div className="flex flex-col items-center bg-center bg-cover w-screen h-full py-16 mt-16">
                <h1 className="text-3xl text-center font-bold mt-8">The Rave Company's History</h1>
                <Link to="/timeline" className="text-center my-8 w-60 bg-red-500 p-4 rounded-lg text-white font-bold hover:bg-red-700">Our Timeline</Link>                               
            </div>*/}
        </main>
        
     );
}
export default About;