import { Link } from "react-router-dom";
import React from "react";
import MenuBackground from "../assets/background.jpg";
import ComBackground from "../assets/companiesBg.svg";
import "../components/dropdown.css";
import Energy from "../assets/companies/energy.jpg";
import Solar from "../assets/companies/helios.png";
import Pay from "../assets/companies/pay.jpg";
import Travel from  "../assets/companies/traveltech.jpg";
import Tzmedia from "../assets/companies/tzmedia.jpg";

const Home = () => {
    return (
        <main>
            <div className="hidden lg:flex xl:flex text-center text-sm font-normal py-4 tracking-wider">
                <Link to="/rave-companies" className="w-1/5 hover:text-red-500 cursor-pointer pl-8 z-40">COMPANIES</Link>
                <div class="group flex justify-center mx-auto">
                    <div class=" groupa-hover relative pl-8">
                        <button class="hover:text-red-500 flex items-center min-w-32 ">
                            <span class="flex-1 h-6 ">WHO WE ARE</span>
                            <svg class="fill-current w-4 transform groupa-hover:-rotate-180
                                        transition duration-150 ease-in-out"
                                viewBox="0 0 20 20">
                                <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                            </svg>
                        </button>
                        <ul class="bg-white border:none w-44 rounded-md transform scale-0 groupa-hover:scale-100 absolute transition duration-300 ease-in-out origin-top min-w-32">
                            <Link to="/about-rave" className="block px-4 py-2 pt-6 text-sm hover:text-red-500">ABOUT US</Link>
                            {/*<Link to="/about-rave" class="block px-4 py-2 pb-4 text-sm hover:text-red-500"> OUR TIMELINE</Link>*/}
                        </ul>
                    </div>
                </div>
                <Link to="/contact-us" className="hidden md:block w-1/5 hover:text-red-500 cursor-pointer pl-10 z-40">CONTACT</Link>
                <a href="https://blog.theravecompany.net/" className="hidden md:block w-1/5 hover:text-red-500 cursor-pointer z-40">BLOG</a>
                {/*<a href="http://club.theravecompany.net/" className="w-1/5 hover:text-red-500 cursor-pointer z-40">RAVE CLUB</a>*/}
                <Link to="/rave-careers" className="hidden md:block w-1/5 hover:text-red-500 cursor-pointer z-40">WORKING AT RAVE</Link>
            </div>
            <div className="flex justify-start pl-0 lg:pl-36 xl:pl-36 items-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${MenuBackground})`, height: "700px" }}>
                <div className="flex flex-col items-center lg:items-start xl:items-start text-white">
                    <h1 className="font-light text-center lg:text-left xl:text-left">THE RAVE COMPANY</h1>
                    <h2 className="text-5xl lg:text-6xl xl:text-6xl text-center lg:text-left xl:text-left font-bold mt-2 mb-1 max-w-auto lg:max-w-lg xl:max-w-lg px-1 lg:px-0 xl:px-0">Extreme Expertise For Your Business</h2>
                    <a href="https://blog.theravecompany.net/" className="text-center my-8 w-60 p-4 border border-gray-200 rounded-lg text-white font-bold transition-all duration-300 ">Latest from Rave</a>
                </div>
            </div>
            <div className="items-center bg-cover bg-no-repeat w-auto overflow-hidden pt-16 pb-24 lg:pb-64 xl:pb-64" style={{ backgroundImage : `url(${ComBackground})` }}>
                <h1 className="text-5xl font-bold text-center mb-16 mx-4">See what we’re made of.</h1>
                <div className="flex flex-col items-center">
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row my-2">
                        <a href="https://raveenergy.net" className="flex justify-center mx-4  bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Energy} alt="Rave Energy" className="object-contain" height="100%" width="300px" />
                        </a>
                        <a href="https://heliosenergy.us/" className="mt-8 lg:mt-0 xl:mt-0 flex justify-center mx-4  bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Solar} alt="Helios Energy" className="object-contain" height="100%" width="250px" />
                        </a>
                        <a href="https://www.ravepay.us/" className="mt-8 lg:mt-0 xl:mt-0 flex justify-center mx-4  bg-white h-72  w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Pay} alt="Rave Pay" className="object-contain" height="100%" width="170px" />
                        </a>
                    </div>
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row mt-8">
                        <a href="https://raveit.io" className="flex justify-center mx-4 bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Travel} alt="Rave Travel Technologies" className="object-contain" height="100%" width="200px" />
                        </a>
                        <a href="https://tzmedia.us" className="flex justify-center mt-8 lg:mt-0 xl:mt-0 mx-4  bg-white h-72 w-72 p-8 object-contain hover:shadow-lg transtition-all duration-300 rounded-lg">
                            <img src={Tzmedia} alt="TZ Media" className="object-contain" height="100%" width="300px" />
                        </a>
                    </div>  
                    <Link to="/rave-companies" className="text-center mt-24 w-60 bg-red-500 p-4 rounded-lg text-white font-bold hover:bg-red-700 transition-all duration-300">See all Rave Companies</Link>         
                </div>
            </div>
        </main>
        
    );
}

export default Home;